<template>
  <div class="publicParamsPage">
    <Listcontainer :searchText.sync="searchText" :placeholder="`参数名称/参数代码`" :pagination="pagination" @fetch="loadData">
      <template v-slot:grid>
        <Grid :Gridtype="1" :GridData="data" />
      </template>
      <template v-slot:defalut> defalut-slot </template>
      <template v-slot:table>
        <Basetable :data="data" :columns="columns" :operations="operations" />
      </template>
    </Listcontainer>
  </div>
</template>
<script>
import Basetable from '@/components/table/base-table/base-table';
import Listcontainer from '@/components/list-container/list-container';
import Grid from '@/components/grid/index.vue';
export default {
  components: {
    Basetable,
    Listcontainer,
    Grid
  },
  data() {
    return {
      searchText: '',
      data: [],
      isupload: false,
      fileList: [],
      groupId: '',
      drawer: false,
      TemplateData: [],
      NestGroupData: [],
      columns: [
        {
          prop: 'name',
          label: '参数名称'
        },
        {
          prop: 'code',
          label: '参数代码'
        },
        {
          prop: 'valueType',
          label: '数据类型'
        },
        {
          prop: 'unit',
          label: '单位'
        },
        {
          prop: 'status',
          label: '报警属性'
        }
      ],
      pagination: {
        total: 0,
        pageSize: 20,
        currentPage: 1
      },
      operations: {
        width: 100,
        handles: []
      },
      isdels: false,
      paramIds: [],
      paramId: ''
    };
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  mounted() {
    let item;
    if (localStorage.getItem('this_basicGroup')) {
      item = JSON.parse(localStorage.getItem('this_basicGroup'));
      if (item === this.$route.query.data) {
        this.loadData(item);
      } else {
        if (this.$route.query.data !== '[object Object]') {
          item = this.$route.query.data;
          localStorage.setItem('this_basicGroup', JSON.stringify(item));
          this.loadData(item);
        } else {
          item = JSON.parse(localStorage.getItem('this_basicGroup'));
          this.loadData(item);
        }
      }
    } else {
      if (this.$route.query.data) {
        item = this.$route.query.data;
        localStorage.setItem('this_basicGroup', JSON.stringify(item));
        this.loadData(item);
      }
    }
  },
  methods: {
    add() {
      localStorage.setItem('isGroup', 'group');
      this.$router.push({ path: '/basicParams/editparams', query: { data: null } });
    },
    edit(item) {
      localStorage.setItem('isGroup', 'group');
      this.$router.push({ path: '/basicParams/editparams', query: { data: { ...item, isGroup: true } } });
    },
    async loadData(param = {}) {
      const { currentPage, pageSize } = this.pagination;
      const data = {
        currentPage: param.currentPage || currentPage,
        currentPageSize: param.currentPageSize || pageSize,
        keyword: this.searchText,
        groupId: JSON.parse(localStorage.getItem('this_basicGroup')).groupId
      };
      const res = await this.$apis.publicParams.list(data);
      this.data = res.items;
      this.data.forEach(item => {
        item.isAlarm === 0 ? (item.status = '否') : (item.status = '是');
      });
      this.pagination = {
        total: res.total,
        pageSize: res.currentPageSize,
        currentPage: res.currentPage
      };
      this.Gridtype = 1;
    },
    downTemplate() {
      this.$apis.basicParamsGroup.downloadTemplate();
    },
    changeFile(file) {
      this.fileList.push(file);
    },
    cloceUploadFile() {
      this.fileList = [];
      this.isupload = false;
    },
    async uploadFile() {
      const item = JSON.parse(localStorage.getItem('this_basicGroup'));
      const groupId = item.groupId;
      const file = this.fileList[0].raw;
      const { fail } = await this.$apis.basicParamsGroup.importParam({ groupId, file });
      if (fail) {
        // eslint-disable-next-line no-useless-return
        return;
      }
      this.fileList = [];
      this.isupload = false;

      this.loadData(item);
    },
    async confirmDel() {
      const { fail } = await this.$apis.publicParams.del({ paramIds: this.isdels ? this.paramIds.join(',') : this.paramId, type: 2 });
      this.drawer = false;
      this.loadData();
      if (fail) {
        return false;
      }
    },
    async deleteRow(row) {
      this.isdels = false;
      this.paramId = row.paramId;
      const { deviceParamGroupVoList, deviceTemplateVoList } = await this.$apis.publicParams.previewCite({ paramIds: row.paramId, type: 2 });
      if (deviceParamGroupVoList.length || deviceTemplateVoList.length) {
        this.NestGroupData = deviceParamGroupVoList;
        this.TemplateData = deviceTemplateVoList;
        this.drawer = true;
      } else {
        this.$confirm('确认要删除已选参数吗?', '删除参数', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(async () => {
          const { fail } = await this.$apis.publicParams.del({ paramIds: row.paramId, type: 1 });
          this.loadData();
          if (fail) {
            return false;
          }
        });
      }
    },
    closeDrawer() {
      this.drawer = false;
    },
    exportFile() {
      if (this.data.length === 0) {
        this.$message.warning('无可导出的数据');
        return false;
      }
      const item = JSON.parse(localStorage.getItem('this_basicGroup'));
      const data = {
        groupId: item.groupId
      };
      this.$apis.basicParamsGroup.ExportParam(data);
    }
  }
};
</script>

<style lang="scss" scoped>
tr th {
  background-color: #f3f3f3 !important;
}
.el-table thead {
  color: #585858 !important;
  font-size: 14px;
}
.el-icon-menu {
  margin-left: 20px;
  cursor: pointer;
}
.publicParamsPage {
  height: 100%;
}
/deep/ .header > .addOrdel {
  display: none;
}
</style>
